.fijuplay__experience {
    display: flex;
    flex-direction: row;
}

.fijuplay__experience-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-left: 2rem;
}

.fijuplay__experience-image img {
    width: 100%;
    height: 100%;
}

.fijuplay__experience-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-end;
}

.fijuplay__experience-content h1{
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 54px;
    line-height: 75px;
    margin: 1rem 0;

}


.fijuplay__experience-content p{
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 36px;
    line-height: 60px;

    margin-top: 6rem;
    margin-bottom: 2rem;
    
}

@media screen and (max-width: 950px) {
    .fijuplay__experience {
        flex-direction: column;
    }

    .fijuplay__experience-image {
        margin: 1rem 0;
    }

    .fijuplay__experience-image img {
        width: unset;
        height: unset;
    }

    .fijuplay__experience-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .fijuplay__experience-image img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 550px) {
    .fijuplay__experience-image img {
        width: 100%;
        height: 100%;
    }
    .fijuplay__experience-content h1{
        font-size: 42px; 
    }
}
