.fijuplay__header {
    display: flex;
}


.fijuplay__header-content{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.fijuplay__header-content h1 {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 62px;
    line-height: 75px;
}

.fijuplay__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.fijuplay__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
  

    display: flex;
    flex-direction: row;
}


.fijuplay__header-content__input input{
    flex: 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0.75rem 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.fijuplay__header-content__input button{
    flex: 0.4;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0.5rem 1.5rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.fijuplay__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.fijuplay__header-content__people img {
    width: 54px;
    height: 38px;
}

.fijuplay__header-content__people p {
    margin: 0 0 0 0rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.fijuplay__header-image {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fijuplay__header-image img{
    width: 100%;
    height: 95%;
}




@media screen and (max-width: 1050px) {
    .fijuplay__header{
        flex-direction: column;
    }

    .fijuplay__header-content {
        margin: 0 0 3rem;
    }
}


@media screen and (max-width: 650px) {
    .fijuplay__header h1{
        font-size: 48px;
        line-height: 60px;
    }

    .fijuplay__header p{
        font-size: 16px;
        line-height: 24px;
    }
    .fijuplay__header-content__people{
        flex-direction: column;
    }
    .fijuplay__header-content__people p{
        margin: 0;
    }
    .fijuplay__header-content__input input,
    .fijuplay__header-content__input button{
        font-size: 16px;
        line-height: 24px;
    }
}


@media screen and (max-width: 490px) {
    .fijuplay__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .fijuplay__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .fijuplay__header-content__input input,
    .fijuplay__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

