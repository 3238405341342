.fijuplay__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    padding: 0.5rem  3rem;
}

.fijuplay__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fijuplay__navbar-links_logo {
    margin-right: 0.5rem;
}

/*Modify logo width and height*/
.fijuplay__navbar-links_logo img {
   width: 100%;
   height:  164px;
}

.fijuplay__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.fijuplay__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fijuplay__navbar-links_container p,
.fijuplay__navbar-sign p,
.fijuplay__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: o 1rem;
    cursor: pointer;
}


.fijuplay__navbar-sign button,
.fijuplay__navbar-links_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    background: #ff4820;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 1050px) {
    .fijuplay__navbar-links_container {
        display: none;
    }

    .fijuplay__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .fijuplay__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .fijuplay__navbar {
        padding: 2rem;
    }

    .fijuplay__navbar-sign {
        display: flex;
    }

    .fijuplay__navbar-menu_container {
        top: 20px;
    }

    .fijuplay__navbar-menu_container-links-sign {
        display: block;
    }
}


