.fijuplay__footer {
    display: flex;
    color: white;
    flex-direction: column;

    align-items: center;
    background: var(--color--footer);
}

.fijuplay__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;

    width: 100%;
    text-align: left;
   
}

.fijuplay__footer-links div {
    width: 250px;
    margin: 1rem;

    cursor: pointer;
}

.fijuplay__footer-links_logo {
    display: flex;
    flex-direction: column;
}

